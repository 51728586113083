import React from "react";

const EmbedForm = ({ onChange, state: { url = "" } }) => {
    return (
        <>
            <div className="form-group">
                <label className="mb-1">HTML Code</label>
                <textarea
                    className="form-control"
                    rows={6}
                    value={url}
                    style={{
                        height: "500px",
                    }}
                    onChange={(e) => onChange({ url: e.target.value })}
                />
            </div>
        </>
    );
};

export default EmbedForm;
