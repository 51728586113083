import React from "react";
import { Field } from "formik";
import { Radio as ChakraRadio, RadioGroup, Text } from "@chakra-ui/core";
import styled from "@emotion/styled";
import { EditorTheme } from "../../theme";

const RadioStyles = styled.div`
    input[type="radio"] {
        + div[type="radio"] {
            border-width: 1px;
        }

        &:checked {
            + div[type="radio"] {
                border-color: ${EditorTheme.colors.brand.orange};

                > span {
                    background-color: ${EditorTheme.colors.brand.orange};
                    height: 70%;
                    width: 70%;
                }
            }
        }
    }
`;

export const Radio = (props) => {
    return (
        <>
            {props?.label?.length > 0 && (
                <Text mb="8px" fontWeight={600}>
                    {props.label}
                    {props.required && (
                        <Text as="sup" color="brand.red">
                            *
                        </Text>
                    )}
                </Text>
            )}
            {props?.options?.length > 0 && (
                <Field name={props.name}>
                    {({ field, form }) => (
                        <RadioStyles>
                            <RadioGroup
                                onChange={(e) =>
                                    form.setFieldValue(
                                        field.name,
                                        e.target.value
                                    )
                                }
                                value={field.value || props.defaultValue}
                                mb={"15px"}
                            >
                                {props.options.map((option, idx) => (
                                    <ChakraRadio
                                        key={`${field.name}-${idx}`}
                                        mb={"5px"}
                                        aria-label={option.label}
                                        size={`md`}
                                        variantColor="brandBlack"
                                        borderColor="brand.grey"
                                        isDisabled={
                                            form.isSubmitting || props.disabled
                                        }
                                        value={option.value}
                                        color="brand.orange"
                                    >
                                        {option?.label?.length > 0 && (
                                            <Text
                                                fontSize="14px"
                                                my="0"
                                                color="brand.white"
                                                fontWeight={600}
                                            >
                                                {option.label}
                                            </Text>
                                        )}
                                    </ChakraRadio>
                                ))}
                            </RadioGroup>
                        </RadioStyles>
                    )}
                </Field>
            )}
            {props?.help?.length > 0 && (
                <Text
                    color="brand.lightGrey"
                    fontSize="sm"
                    mb={{
                        base: `15px`,
                        md: `30px`,
                    }}
                >
                    {props.help}
                </Text>
            )}
        </>
    );
};
