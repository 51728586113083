import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brandBlack: {
            500: "#000000", // used for input elements
        },
        brandOrange: {
            500: "#EB5D0B", // used for input elements
        },
        brand: {
            black: "#000000",
            orange: "#EB5D0B",
            white: "#FFFFFF",
            lightGrey: "#F2F2F2",
            grey: "#505050",
            darkGrey: "#222222",
            red: "#BD2F2E",
            darkPink: "#A9296A",
            lightPink: "#C04183",
            lightPurple: "#854890",
            darkPurple: "#673F8A",
            indigo: "#45418B",
            green: "#2DB200",
            paleGreen: "#E9F7E5",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "proxima-nova, sans-serif",
        body: "proxima-nova, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const listStyles = css`
    ul:not([class^="DayPicker"]),
    ol {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        padding-left: 0;
        font-size: "14px";

        li {
            margin-bottom: 15px;
            position: relative;
            &:before {
                display: block;
                content: "";
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    ul:not([class^="DayPicker"]) {
        li {
            padding-left: 15px;

            &:before {
                position: absolute;
                left: 0;
                top: 10px;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: ${EditorTheme.colors.brand.white};
            }
        }
    }

    ol {
        counter-reset: list-number;
        li {
            display: flex;
            counter-increment: list-number;
            &:before {
                margin-right: 5px;
                content: counter(list-number) ".";
            }
        }
    }
`;

const headings = css`
    h1 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 15px;
        font-weight: 600;

        @media (max-width: 478px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 15px;
        font-weight: 600;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }

        &.heading2-huge {
            font-size: 80px;
            line-height: 80px;
        }

        &.heading2-huge__outline {
            margin-bottom: 0px;
            font-size: 80px;
            line-height: 80px;

            @supports (
                (text-stroke: 2px white) or (-webkit-text-stroke: 2px white)
            ) {
                color: transparent !important;
                -webkit-text-stroke: 2px ${EditorTheme.colors.brand.orange};
                text-stroke: 2px ${EditorTheme.colors.brand.orange};
                text-shadow: none;

                span {
                    color: transparent !important;
                }
            }
        }
    }

    h3 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 15px;

        @media (max-width: 478px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h4 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    h6 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    blockquote {
        font-size: 24px;
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: 5px solid ${EditorTheme.colors.brand.orange} !important;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
`;

const table = css`
    .table {
        color: ${EditorTheme.colors.brand.white};
        width: 100%;
        border-color: transparent !important;

        td {
            padding: 0.75rem;
            vertical-align: top;
            border-color: transparent !important;
        }

        .ck-editor__nested-editable_focused {
            color: ${EditorTheme.colors.brand.grey} !important;
        }
    }
`;

const focusVisible = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

const reactPage = css`
    .ory-row {
        a:not([type="button"]) {
            text-decoration: none;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
                color: ${EditorTheme.colors.brand.orange};
            }
        }

        .content__button {
            border-radius: 0;
        }

        .container__match-height {
            .content__text {
                display: table !important;

                * {
                    display: block !important;
                }
            }
        }

        .image {
            display: table !important;
            clear: both !important;
            text-align: center !important;
            margin: 1em auto !important;

            &.image-style-side {
                float: right;
                margin-left: 1.5em !important;
                max-width: 50% !important;

                img {
                    display: block !important;
                    margin: 0 auto !important;
                    max-width: 100% !important;
                    height: auto !important;
                    min-width: 50px !important;
                }
            }
        }

        figure.table {
            table {
                margin: 15px 0;
                min-width: 100%;
                font-size: 14px;

                tbody {
                    > tr {
                        &:first-of-type {
                            font-weight: bold;
                            font-size: 16px;
                        }
                        &:nth-child(odd) {
                            background-color: rgba(255, 255, 255, 0.4);
                        }

                        &:nth-child(even) {
                            background-color: transparent;
                        }
                    }

                    tr {
                        td {
                            padding: 15px;
                        }
                    }
                }
            }
        }

        .react-select {
            p {
                margin-bottom: 0px;
            }
        }
    }
`;

const carousel = css`
    .carousel-root {
        width: 100%;
    }

    .carousel {
        ul {
            margin-bottom: 0;
            list-style: none;

            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }
    }

    .carousel .slide {
        text-align: left;
        background: transparent;

        ul {
            margin-bottom: 0;
            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }
    }

    .carousel.carousel-slider {
        overflow: visible;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .carousel {
        .control-dots {
            display: flex;
            justify-content: center;
            bottom: -40px;
        }
    }
`;

export const GlobalStyles = css`
    ${listStyles}
    ${headings}
    ${table}
    ${carousel}
    ${focusVisible}
    ${reactPage}
`;
