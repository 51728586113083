export const primary = {
    accentColor: "rgba(235,93,11,1)",
    textColor: "rgba(255,255,3255,1)",
    sharedProps: {
        px: "1em",
        lineHeight: "45px",
        height: "100%",
        borderRadius: "4px",
    },
    solidProps: { color: "brand.white" },
    outlineProps: { color: "brand.orange" },
};

export const secondary = {
    textColor: "rgba(34,34,34,1)",
    accentColor: "rgba(216,216,216,1)",
    sharedProps: {
        px: "1em",
        lineHeight: "45px",
        height: "100%",
        borderRadius: "4px",
    },
};

export const tertiary = {
    accentColor: "rgba(235,93,11,1)",
    textColor: "rgba(255,255,255,1)",
    sharedProps: {
        px: "1em",
        lineHeight: "45px",
        height: "100%",
        borderRadius: "4px",
    },
    solidProps: { color: "brand.white" },
    outlineProps: {
        color: "brand.white",
        borderColor: "brand.white",
        _hover: {
            backgroundColor: "brand.white",
            color: "brand.black",
        },
        _active: {
            color: "brand.black",
            backgroundColor: "#b2b2b2",
            borderColor: "#b2b2b2",
        },
    },
};

export const success = {
    accentColor: "rgba(45,178,0,1)",
    textColor: "rgba(45, 178, 0,1)",
    sharedProps: {
        px: "1em",
        lineHeight: "45px",
        height: "100%",
        borderRadius: "4px",
    },
    solidProps: { color: "brand.white" },
};
