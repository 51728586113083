import React from "react";
import { Field, FieldArray } from "formik";

import { Checkbox as ChakraCheckbox, Text, Stack } from "@chakra-ui/core";

export const CheckboxGroup = (props) => {
  return (
    <>
      {props?.label?.length > 0 && (
        <Text fontWeight="600" mb="8px">
          {props.label}
          {props.required && (
            <Text as="sup" color="brand.orange">
              *
            </Text>
          )}
        </Text>
      )}
      {props?.options?.length > 0 && (
        <Field name={props.name}>
          {({ field, form }) => (
            <FieldArray name={field.name}>
              {({ push, remove }) => (
                <Stack mb="15px">
                  {props.options.map((option, idx) => (
                    <ChakraCheckbox
                      mb={
                        props.help
                          ? `5px`
                          : {
                              base: `15px`,
                              md: `30px`,
                            }
                      }
                      aria-label={props.label}
                      size={`md`}
                      variantColor="brandOrange"
                      borderColor={`brand.grey`}
                      checked={field.value}
                      isDisabled={form.isSubmitting || props.disabled}
                      iconColor="brand.black"
                      onChange={(e) => {
                        const target = e.currentTarget;

                        if (target.checked) {
                          push(target.id);
                        } else {
                          remove(idx);
                        }
                      }}
                      {...props}
                    >
                      {option?.label?.length > 0 && (
                        <Text fontSize="14px" my="0 !important">
                          {option.label}
                        </Text>
                      )}
                    </ChakraCheckbox>
                  ))}
                </Stack>
              )}
            </FieldArray>
          )}
        </Field>
      )}
      {props?.help?.length > 0 && (
        <Text
          color="brand.lightGrey"
          fontSize="sm"
          mb={{
            base: `15px`,
            md: `30px`,
          }}
        >
          {props.help}
        </Text>
      )}
    </>
  );
};
