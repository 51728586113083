import React from "react";
import ReactSelect, { components } from "react-select";

import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { Stack, Text } from "@chakra-ui/core";
import { EditorTheme } from "../../theme";

export const Select = (props) => {
    const { name, options, label, required } = props;

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    {label?.length > 0 && (
                        <Text mb="8px" fontWeight={600}>
                            {label}
                            {required && (
                                <Text as="sup" color="brand.red">
                                    *
                                </Text>
                            )}
                        </Text>
                    )}

                    <Stack mb="8" justifyContent="center">
                        <ReactSelect
                            onChange={(item) => {
                                form.setFieldValue(field.name, item.value);
                            }}
                            {...(field?.value?.length > 0 && {
                                value: options.filter(
                                    (option) => option.label === field.value
                                ),
                            })}
                            options={options}
                            className="react-select"
                            styles={{
                                control: (provided, { isFocused }) => ({
                                    ...provided,
                                    padding: "0 5px",
                                    backgroundColor:
                                        EditorTheme.colors.brand.black,
                                    borderColor:
                                        field.value?.length > 0
                                            ? EditorTheme.colors.brand.white
                                            : EditorTheme.colors.brand.grey,
                                    color: EditorTheme.colors.brand.white,
                                    boxShadow: isFocused ? "none" : "none",
                                    fontSize: "14px",
                                    fontFamily: EditorTheme.fonts.body,
                                    borderRadius: "0px",
                                    "&:hover": {
                                        borderColor:
                                            EditorTheme.colors.brand.white,
                                    },
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: EditorTheme.colors.brand.white,
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                    margin: "0",
                                    left: "0",
                                    padding: "0px",
                                    color: EditorTheme.colors.brand.white,
                                    backgroundColor:
                                        EditorTheme.colors.brand.black,
                                    border: "1px solid",
                                    borderColor: EditorTheme.colors.brand.grey,
                                    borderRadius: "0px 0px 3px 3px",
                                }),
                                option: (
                                    provided,
                                    { isDisabled, isFocused }
                                ) => ({
                                    ...provided,
                                    padding: "10px 15px",
                                    fontSize: "14px",
                                    color: EditorTheme.colors.brand.white,
                                    opacity: isDisabled ? 0.7 : 1,
                                    backgroundColor: isFocused
                                        ? EditorTheme.colors.brand.grey
                                        : EditorTheme.colors.brand.black,
                                    "&:active": {
                                        backgroundColor:
                                            EditorTheme.colors.brand.black,
                                    },
                                }),
                            }}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                            }}
                            data-testid="select-input"
                        />
                    </Stack>
                </>
            )}
        </Field>
    );
};

const CaretDownIcon = () => {
    return (
        <Text color={`black`}>
            <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                    height: `14px`,
                    width: `14px`,
                    display: `flex`,
                    color: EditorTheme.colors.brand.white,
                }}
            />
        </Text>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};
