import { faTachometerAltSlow } from "@fortawesome/pro-regular-svg-icons/faTachometerAltSlow";
import { faBrowser } from "@fortawesome/pro-regular-svg-icons/faBrowser";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";

const ROLE_ADMIN = "ROLE_ADMIN";

export const menu = {
    dashboard: {
        label: "Dashboard",
        icon: faTachometerAltSlow,
        path: "/",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
    },
    content: {
        label: "Content",
        icon: faBrowser,
        sortOrder: 50,
        roles: [ROLE_ADMIN],
        children: {
            pages: {
                label: "Pages",
                path: "/pages",
                sortOrder: 10,
                roles: [ROLE_ADMIN],
            },
            articles: {
                label: "Articles",
                path: "/articles",
                sortOrder: 20,
                roles: [ROLE_ADMIN],
            },
            articleCategories: {
                label: "Article Categories",
                path: "/article-categories",
                sortOrder: 30,
                roles: [ROLE_ADMIN],
            },
            templates: {
                label: "Templates",
                path: "/templates",
                sortOrder: 40,
                roles: [ROLE_ADMIN],
            },
            elements: {
                label: "Elements",
                path: "/elements",
                sortOrder: 50,
                roles: [ROLE_ADMIN],
            },
            elementAreas: {
                label: "Element Areas",
                path: "/element-areas",
                sortOrder: 60,
                roles: [ROLE_ADMIN],
            },
            forms: {
                label: "Forms",
                icon: "map-pin",
                sortOrder: 70,
                roles: [ROLE_ADMIN],
                children: {
                    allForms: {
                        label: "All Forms",
                        path: "/forms",
                        sortOrder: 10,
                        roles: [ROLE_ADMIN],
                    },
                    formFields: {
                        label: "Form Fields",
                        path: "/form-fields",
                        sortOrder: 20,
                        roles: [ROLE_ADMIN],
                    },
                    formSubmissions: {
                        label: "Form Submissions",
                        path: "/form-submissions",
                        sortOrder: 30,
                        roles: [ROLE_ADMIN],
                    },
                },
            },
        },
    },

    users: {
        label: "Users",
        icon: faUsers,
        path: "/users",
        sortOrder: 70,
        roles: [ROLE_ADMIN],
    },
    configuration: {
        label: "Configuration",
        icon: faSlidersH,
        sortOrder: 80,
        roles: [ROLE_ADMIN],
        children: {
            settings: {
                label: "Settings",
                path: "/settings",
                sortOrder: 10,
                roles: [ROLE_ADMIN],
            },
            navigation: {
                label: "Navigation",
                path: "/menus",
                sortOrder: 20,
                roles: [ROLE_ADMIN],
            },
            redirects: {
                label: "Redirects",
                path: "/redirects",
                sortOrder: 30,
                roles: [ROLE_ADMIN],
            },

            tasks: {
                label: "Tasks",
                path: "/tasks",
                sortOrder: 50,
                roles: [ROLE_ADMIN],
            },
        },
    },
};
