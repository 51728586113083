import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";
import {
    Checkbox,
    CheckboxGroup,
    Input,
    Radio,
    Select,
    Textarea,
    Button,
} from "./Inputs";

import blocks from "./blocks";
import { menu } from "./menu";
import compact from "lodash/compact";
import startCase from "lodash/startCase";

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [PeractoUser(), PeractoCommon(), PeractoContent()],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: compact(
            Object.entries(EditorTheme.colors.brand).map(([label, value]) => {
                // Filter white, because it's used by the default colours and we don't want to duplicate it.
                if (["#FFFFFF"].includes(value)) return null;
                return { label: startCase(label), value };
            })
        ),
        headings: [
            {
                model: "hugeHeading2",
                title: "Huge Heading 2",
                class: "ck-heading_heading1",
                view: {
                    name: "h2",
                    classes: "heading2-huge",
                },
                converterPriority: "high",
            },
            {
                model: "hugeHeading",
                title: "Huge Heading",
                class: "hugeHeadingList",
                view: {
                    name: "h2",
                    classes: "heading2-huge__outline",
                },
                converterPriority: "high",
            },
        ],
        locales: ["en_GB"],
        blocks,
        inputTypes: {
            Checkbox,
            CheckboxGroup,
            Input,
            Radio,
            Select,
            Textarea,
            Button,
        },
        stylesheets: ["https://use.typekit.net/xbj2sbo.css"],
        bodyStyles: {
            backgroundColor: EditorTheme.colors.brand.black,
            color: EditorTheme.colors.brand.white,
        },
        globalStyles: GlobalStyles,
    },
    features: {
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            exportProductData: true,
            productVariants: true,
        },
        content: {
            indexContent: true,
        },
    },
    menu,
});

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
);

export default App;
