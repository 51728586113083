import createPlugin from './createPlugin'
import EmbedRenderer from './Renderer/EmbedRenderer'

const embedPlugin = settings =>
    createPlugin({
        Renderer: EmbedRenderer,
        ...settings,
    })

const embed = embedPlugin()
export default embed

export { embedPlugin, EmbedRenderer }
