import React from "react";
import { Field } from "formik";

import { Stack, Checkbox as ChakraCheckbox, Text } from "@chakra-ui/core";

export const Checkbox = (props) => (
  <Stack className="ory-checkbox">
    <Field name={props.name}>
      {({ field, form }) => (
        <ChakraCheckbox
          mb={
            props.help
              ? `5px`
              : {
                  base: `15px`,
                  md: `30px`,
                }
          }
          aria-label={props.label}
          size={`md`}
          variantColor="brandOrange"
          borderColor={`brand.grey`}
          checked={field.value}
          onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
          isDisabled={form.isSubmitting || props.disabled}
          iconColor="brand.black"
          {...props}
        >
          {props?.label?.length > 0 && (
            <Text fontSize="14px" fontWeight="600" my="0">
              {props.label}
              {props.required && (
                <Text as="sup" color="brand.red">
                  *
                </Text>
              )}
            </Text>
          )}
        </ChakraCheckbox>
      )}
    </Field>

    {props?.help?.length > 0 && (
      <Text
        color="brand.lightGrey"
        fontSize="sm"
        mb={{
          base: `15px`,
          md: `30px`,
        }}
      >
        {props.help}
      </Text>
    )}
  </Stack>
);
