import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/pro-regular-svg-icons/faCode";
import InnerHTML from "dangerously-set-html-content";

const IFrameContent = styled.div`
    position: relative;
`;

const IFrameOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
`;

let Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faCode} size="6x" />
    </div>
);

const EmbedComponent = ({ state: { url }, isEditMode }) => {
    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <IFrameContent className="content__embed">
            {url && url.length > 0 ? (
                <>
                    <Content url={url} />
                    {isEditMode && <IFrameOverlay />}
                </>
            ) : inStorefront ? (
                <></>
            ) : (
                <Icon />
            )}
        </IFrameContent>
    );
};

const Content = ({ url }) => {
    return <InnerHTML html={url} />;
};

export default EmbedComponent;
